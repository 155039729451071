<template>
  <v-container
    fluid
    fill-height
    :class="$style.auth"
    class="d-flex justify-center align-center flex-column pa-0"
  >
    <v-overlay :value="processing">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div>
      <v-row>
        <v-col cols="12" class="text-center">
          <img :src="require('@/assets/logo.png')" width="320" />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="d-flex justify-center">
          <v-card
            elevation="0"
            color="#fff"
            class="py-0 pa-4 rounded-xl"
            rounded
            :class="$style.form"
            width="360"
          >
            <error-list :errorMessages="errorMessages" :names="['message']" />
            <v-row dense>
              <v-col cols="12">
                <MyForm
                  :formKey="formKey"
                  :entity="entity"
                  :input="input"
                  ref="form"
                >
                  <v-container class="pa-0">
                    <v-row dense>
                      <v-col cols="12">
                        <v-text-field
                          v-model="mailAddress"
                          label="メールアドレス"
                          clearable
                          autocomplete="off"
                          :error-messages="
                            errorMessages.mailAddress &&
                              errorMessages.mailAddress[0]
                          "
                          background-color="#fff"
                        >
                          <v-icon slot="prepend">
                            mdi-email-outline
                          </v-icon>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <v-text-field
                          v-model="password"
                          type="password"
                          label="パスワード"
                          clearable
                          autocomplete="off"
                          :error-messages="
                            errorMessages.password && errorMessages.password[0]
                          "
                          background-color="#fff"
                        >
                          <v-icon slot="prepend">
                            mdi-lock-outline
                          </v-icon>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </MyForm>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="10">
                <base-btn
                  :disabled="signinButtonDisabled"
                  label="サインイン"
                  @click="onLogin"
                />
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="10">
                <base-btn
                  label="新規登録"
                  @click="onCreate"
                  :outlined="true"
                  :style="{ color: '#ff7272' }"
                />
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="10">
                <a
                  class="text-center d-block"
                  :class="$style['reissue-link']"
                  href="/login/reissue"
                >
                  ログイン情報を忘れた方へ <v-icon>mdi-chevron-right</v-icon>
                </a>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="10">
          <a
            class="text-center d-block"
            :class="$style['reissue-link']"
            href="/what-jamid-is"
          >
            <v-icon>mdi-help-circle-outline</v-icon>
            <span class="ml-2 mr-1">jaM idとは</span>
            <v-icon>mdi-chevron-right</v-icon>
          </a>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import MyForm from "@/entries/front/components/form";
import ErrorList from "@/entries/front/components/error/error-list";
export default {
  components: {
    MyForm,
    ErrorList
  },
  data() {
    return {
      formKey: "signup",
      input: {
        mailAddress: "",
        password: ""
      },
      fields: [
        {
          label: "メールアドレス",
          name: "mailAddress",
          rules: "required"
        },
        {
          label: "パスワード",
          name: "password",
          rules: "required"
        }
      ]
    };
  },
  computed: {
    mailAddress: {
      get() {
        return this.entity["mailAddress"];
      },
      set(value) {
        this.$store.commit("auth/setEntity", {
          entity: {
            mailAddress: value
          }
        });
      }
    },
    password: {
      get() {
        return this.entity["password"];
      },
      set(value) {
        this.$store.commit("auth/setEntity", {
          entity: {
            password: value
          }
        });
      }
    },
    isError() {
      return this.$store.getters["auth/isError"];
    },
    processing() {
      return this.$store.getters[`auth/processing`];
    },
    entity() {
      return this.$store.getters[`auth/entity`];
    },
    errorMessages() {
      return this.$store.getters[`auth/errorMessages`];
    },
    signinButtonDisabled() {
      return (
        (this.mailAddress === "" ||
          this.mailAddress === null ||
          this.mailAddress === undefined) &&
        (this.password === "" ||
          this.password === null ||
          this.password === undefined)
      );
    }
  },
  methods: {
    onLogin() {
      this.$store
        .dispatch("auth/login", {
          mailAddress: this.mailAddress,
          password: this.password
        })
        .then(() => {
          this.$store.commit("form/clearDirty");
          this.$router.push({ name: "menu" });
        })
        .catch(() => {});
    },
    onCreate() {
      this.$router.push({ name: "signup-userinfo" });
    },
    popstate() {
      window.history.pushState(null, null, null);
      return;
    }
  },
  created() {
    this.$store.commit(`auth/initialize`);
  },
  mounted() {
    window.addEventListener("popstate", this.popstate);
    this.popstate();
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.popstate);
    this.$store.commit("form/changeDirty", [
      {
        key: this.formKey,
        isDirty: !!this.mailAddress
      }
    ]);
  }
};
</script>

<style module lang="scss">
@import "@/styles/common.scss";

.error {
  color: #ff1100;
  text-align: center;
  font-size: 12px;
}

.reissue-link {
  font-size: 16px;
  text-decoration-line: none;
  color: $base-font-color !important;
}
.signin-btn {
  &:disabled {
    opacity: 0.6;
    background-color: #ccc !important;
  }
}
.create-btn {
  border: 2px solid #ff7272;
  color: #ff7272 !important;
}
</style>

<style scope lang="scss">
.theme--light.v-btn.v-btn--disabled {
  color: #fff !important;
}
</style>
